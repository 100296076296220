import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ForgetPassword from '../pages/forget_password/ForgetPassword';
import Harga from '../pages/harga/Harga';
import Landing from '../pages/landing/Landing';
import Login from '../pages/login/Login';
import Register from '../pages/register/Register';
import Verify from '../pages/verify/Verify';
import ForgetPasswordSuccess from '../pages/forget_password/ForgetPasswordSuccess';
import NotFound from '../pages/notFound/NotFound'
import Terms from '../pages/terms/Terms';

function Core() {
  return (
    <Switch>
      <Route component={Landing} exact path="/" />
      <Route component={Terms} exact path="/terms" />
      <Route component={Login} exact path="/login" />
      <Route component={Register} exact path="/register" />
      <Route component={Harga} exact path="/harga" />
      <Route component={Verify} exact path="/verify/:id/:token" />
      <Route component={ForgetPassword} exact path="/reset-password" />
      <Route
        component={ForgetPasswordSuccess}
        exact
        path="/reset-password/success"
      />
      <Route component={NotFound} />
    </Switch>
  );
}

export default Core;
