import React from 'react';

function Landing() {
  return (
    <main>
      <section
        className="
          w-10/12
          h-screen
          mx-auto
          flex flex-col-reverse
          md:flex-row
          justify-center
          items-center
        "
      >
        <div className="flex flex-col items-start md:w-1/2">
          <div className="hidden md:inline">
            <img
              src="images/landing-pages/logo-akronim.png"
              className="items-start"
              alt=""
            />
          </div>
          <div className="mt-12 mb-8 md:mb-0 md:mt-0">
            <p className="font-bold text-3xl md:text-5xl text-center md:text-left">
              Semua Bisa Kelola Kasir Dengan Mudah
            </p>
          </div>
          <div className="my-2 w-full md:w-auto md:my-10 flex justify-center md:inline">
            <button
              className="
                w-2/3
                md:w-auto
                border-2
                rounded-3xl
                border-primary
                py-3
                md:px-8 md:py-2
                bg-primary
                text-white
                font-semibold
                md:shadow-2xl
              "
            >
              Coba Gratis Sekarang
            </button>
          </div>
          <div className="w-full my-4 md:my-0 md:hidden flex justify-center md:inline">
            <button
              className="
                w-2/3
                border-2
                rounded-3xl
                border-primary
                px-15
                py-3
                md:px-8 md:py-2
                bg-white
                text-primary
                font-semibold
                md:shadow-2xl
              "
            >
              Log in
            </button>
          </div>
        </div>
        <div className="md:w-1/2">
          <img src="images/landing-pages/illustration-1.png" alt="" />
        </div>
      </section>

      <section className="w-9/12 mx-auto mb-24 md:hidden">
        <div>
          <p className="text-blue-800 text-center text-3xl font-semibold">
            Solusi Terbaik Tingkatkan Penjualan Kamu dengan Mudah dan Efektif
          </p>
        </div>
        <div className="my-16">
          <img src="images/login-pages/Illustration-login.png" alt="" />
        </div>
      </section>

      <section
        className="py-3 md:py-12 bg-no-repeat bg-cover"
        style={{
          backgroundImage: "url('images/landing-pages/bg-blue-besar.png')",
        }}
      >
        <div className="w-10/12 mx-auto">
          <div className="flex-col items-center w-full hidden md:flex">
            <p className="w-1/2 text-white font-semibold text-center text-4xl">
              Solusi Terbaik Tingkatkan Penjualan Kamu dengan Mudah dan Efektif
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-32 gap-y-16 my-24">
            <div className="flex flex-col items-center md:inline">
              <h1
                className="
                  text-white
                  font-semibold
                  text-2xl text-center
                  md:text-left
                "
              >
                Kendalikan Penjualan Kamu Dimanapun dan Kapanpun
              </h1>
              <p className="text-white my-8 text-center md:text-left">
                Jadikan akses kendali penjualan kamu dapat dilakukan dimanapun
                dan kapanpun dengan Bisa Kasir. Kontrol semua kendali outlet,
                produk dan karyawan juragan dimanapun berada dengan mudah, cepat
                dan efektif.
              </p>
              <button
                className="
                  w-1/2
                  border-2
                  rounded-3xl
                  border-primary
                  px-6
                  py-1
                  bg-primary
                  text-white
                  font-semibold
                "
              >
                Selengkapnya
              </button>
            </div>
            <div className="flex flex-col items-center md:inline">
              <h1
                className="
                  text-white
                  font-semibold
                  text-2xl text-center
                  md:text-left
                "
              >
                Fitur Lengkap Dalam Satu Aplikasi, Sangat Membantu!
              </h1>
              <p className="text-white my-8 text-center md:text-left">
                Jualan kamu dijamin sangat terbantu dengan adanya fitur sangat
                lengkap dari Bisa Kasir. Transaksi penjualan akan berfokus dalam
                satu layanan sistem kasir yang sangat mudah untuk dikelola.
              </p>
              <button
                className="
                  w-1/2
                  border-2
                  rounded-3xl
                  border-primary
                  px-6
                  py-1
                  bg-primary
                  text-white
                  font-semibold
                "
              >
                Selengkapnya
              </button>
            </div>
            <div className="flex flex-col items-center md:inline">
              <h1
                className="
                  text-white
                  font-semibold
                  text-2xl text-center
                  md:text-left
                "
              >
                Solusi Terbaik untuk Para Pengusaha
              </h1>
              <p className="text-white my-8 text-center md:text-left">
                Fokus meningkatkan penjualan adalah tujuan utama para pengusaha,
                maka serahkan Bisa Kasir sebagai sarana penunjangnya.
              </p>
              <button
                className="
                  w-1/2
                  border-2
                  rounded-3xl
                  border-primary
                  px-6
                  py-1
                  bg-primary
                  text-white
                  font-semibold
                "
              >
                Selengkapnya
              </button>
            </div>
            <div className="flex flex-col items-center md:inline">
              <h1
                className="
                  text-white
                  font-semibold
                  text-2xl text-center
                  md:text-left
                "
              >
                Penjualan dan Laporan Transaksi Menjadi Mudah
              </h1>
              <p className="text-white my-8 text-center md:text-left">
                Kamu bisa kontrol dan akses segalanya secara baik dan mudah
                dalam satu aplikasi BisaKasir. Sangat memudahkan pantau laporan
                transaksi dimanapun dan kapanpun.
              </p>
              <button
                className="
                  w-1/2
                  border-2
                  rounded-3xl
                  border-primary
                  px-6
                  py-1
                  bg-primary
                  text-white
                  font-semibold
                "
              >
                Selengkapnya
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="py-8 my-8">
        <div className="w-10/12 mx-auto">
          <div>
            <p className="text-center text-4xl font-semibold text-blue-900">
              Fitur Menarik dan Sangat Membantu
            </p>
          </div>
          <div
            className="
              grid grid-cols-3
              justify-items-center
              my-20
              gap-y-16 gap-x-8
              md:gap-x-0
            "
          >
            <div className="flex flex-col items-center justify-between">
              <img src="images/landing-pages/cashier.png" alt="Cashier" />
              <p className="text-lg my-4 text-center">Sistem Kasir (POS)</p>
            </div>
            <div className="flex flex-col items-center justify-between">
              <img src="images/landing-pages/bottle.png" alt="Bottle" />
              <p className="text-lg my-4 text-center">Produk Lengkap</p>
            </div>
            <div className="flex flex-col items-center justify-between">
              <img src="images/landing-pages/report.png" alt="Report" />
              <p className="text-lg my-4 text-center">
                Laporan Transaksi Realtime
              </p>
            </div>
            <div className="flex flex-col items-center justify-between">
              <img src="images/landing-pages/kitab-suci.png" alt="Kitab Suci" />
              <p className="text-lg my-4 text-center">Aplikasi Islami</p>
            </div>
            <div className="flex flex-col items-center justify-between">
              <img src="images/landing-pages/store.png" alt="Outlet" />
              <p className="text-lg my-4 text-center">Outlet Terintegrasi</p>
            </div>
            <div className="flex flex-col items-center justify-between">
              <img
                src="images/landing-pages/berbagi-link.png"
                alt="berbagi-link"
                className="mt-10"
              />
              <p className="text-lg my-4 text-center">Gratis Mini Website</p>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <p className="text-3xl font-medium text-blue-900 md:w-4/12 text-center">
              Tampilan Nyaman Dengan Fitur Super Aman
            </p>
          </div>
        </div>
      </section>
      <section
        className="my-8 h-36 bg-no-repeat bg-cover md:h-64"
        style={{
          backgroundImage: "url('images/landing-pages/background-2.png')",
        }}
      ></section>
      <section className="py-8 my-2">
        <div className="w-10/12 mx-auto">
          <div
            className="
              h-1/2
              flex flex-col
              md:flex-row
              justify-center
              items-center
              md:justify-between
              my-8
            "
          >
            <img
              className="md:w-2/5"
              src="images/landing-pages/store-illustration.png"
              alt="Store Illustration"
            />
            <div
              className="
                flex flex-col
                justify-center
                items-center
                my-10
                md:my-0 md:w-2/5
              "
            >
              <h1
                className="
                  text-4xl text-blue-900
                  font-semibold
                  w-3/4
                  text-center
                  md:text-left
                "
              >
                Cocok untuk Bisnis Apapun
              </h1>
              <p className="w-3/4 mt-8 text-xl text-center md:text-left">
                So jadi gak perlu khawatir,
                <span className="font-semibold text-primary">
                  apapun bisnisnya BisaKasir Solusinya
                </span>
              </p>
            </div>
          </div>
          <div className="h-1/2 flex flex-col-reverse md:flex-row justify-between my-8">
            <div
              className="
                flex flex-col
                justify-center
                items-center
                my-10
                md:my-0 md:w-2/5
              "
            >
              <h1
                className="
                  text-4xl text-blue-900
                  font-semibold
                  w-3/4
                  text-center
                  md:text-left
                "
              >
                Wajib Prioritaskan Penjualan
              </h1>
              <p className="w-3/4 mt-8 text-xl text-center md:text-left">
                Selama menggunakan aplikasi BisaKasir kamu dituntut untuk
                <span className="font-semibold text-primary">
                  prioritaskan penjualan dengan mudah dan cepat.
                </span>
              </p>
            </div>
            <img
              className="md:w-2/5"
              src="images/landing-pages/illustration-2.png"
              alt="Illustration -2"
            />
          </div>
        </div>
      </section>
      <section
        className="h-48 bg-cover bg-no-repeat bg-right md:h-120"
        style={{
          backgroundImage: "url('images/landing-pages/bg-blue-people.png')",
        }}
      >
        <div className="w-10/12 mx-auto flex flex-col justify-center h-full">
          <h1
            className="
              text-white text-xl
              w-4/5
              md:text-4xl
              font-bold
              md:w-2/5
              leading-10
            "
          >
            Buat Perubahan Besar untuk Bisnismu Sekarang
          </h1>
          <button
            className="
              self-start
              my-6
              border-2
              rounded-3xl
              border-primary
              px-4
              py-2
              md:px-8 md:py-1
              bg-primary
              text-white
              font-semibold
            "
          >
            Coba Gratis Sekarang
          </button>
        </div>
      </section>
      <section className="my-8 py-8">
        <div className="w-10/12 mx-auto flex flex-col">
          <div className="my-4">
            <p className="text-center text-blue-900 text-3xl font-bold">
              Cocok untuk semua bisnis kamu, By the way
            </p>
          </div>
          <div className="flex justify-center mt-8 mb-16 md:mt-0">
            <p className="text-center text-2xl md:w-2/6 text-gray-500">
              BisaKasir hanya tools semata,
              <span className="text-primary">
                kamulah pejuang sesungguhnya :)
              </span>
            </p>
          </div>
          <div
            className="
              grid grid-cols-3
              justify-items-center
              items-center
              gap-x-16
              md:gap-x-0
              gap-y-10
            "
          >
            <img src="images/landing-pages/toko.png" alt="Toko" />
            <img src="images/landing-pages/clothes.png" alt="Clothes" />
            <img src="images/landing-pages/burger.png" alt="Burger" />
            <p>Toko/Restaurant</p>
            <p>Ritel/Clothing</p>
            <p>Food and Beverages</p>
          </div>
        </div>
      </section>
      <section
        style={{
          backgroundImage: "url('images/landing-pages/bg-blue-opacity.png')",
        }}
        className="py-24 bg-no-repeat bg-cover"
      >
        <div className="flex flex-col items-center w-10/12 mx-auto">
          <p
            className="
              text-white text-2xl
              md:text-4xl
              font-bold
              text-center
              md:text-left
            "
          >
            Siapapun Kamu, Berhak untuk Maju dan Sukses Selalu
          </p>
          <div className="justify-center hidden md:flex">
            <p className="my-12 text-white text-2xl w-2/3 text-center">
              Jangan jadikan keraguan sebagai alasanmu untuk tidak memulai
              sesuatu yang baru.
            </p>
          </div>
          <p className="text-white text-2xl font-bold hidden md:flex">
            BisaKasir siap Bantu Kesuksesan Kamu :)
          </p>
          <img
            className="mt-12 w-2/3 md:w-1/6"
            src="images/landing-pages/google-play.png"
            alt="Google Play Logo"
          />
        </div>
      </section>
    </main>
  );
}

export default Landing;
