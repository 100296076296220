import axios from 'axios';
import React, { useEffect, useState } from 'react';
import BASE_URL from '../../config/url';
import { useParams, useHistory } from 'react-router-dom';

function Verify() {
  const history = useHistory();

  let [loading, SetLoading] = useState(true);
  let [message, SetMessage] = useState('');
  let [errorCheck, SetErrorCheck] = useState('');
  const params = useParams();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const { id, token } = params;
      const response = await axios.post(
        `${BASE_URL}/email/verify/${id}/${token}`
      );
      SetLoading(false);
      SetErrorCheck(response.data.error);
      SetMessage(response.data.message);
    } catch (error) {
      SetLoading(false);
      if (error.response && error.response.data) {
        SetErrorCheck(error.response.data.error);
        SetMessage(error.response.data.message);
        console.log(error.response.data);
      } else {
        SetErrorCheck(true);
        SetMessage('Connection error');
      }
    }
  };
  return (
    <div>
      <div className="px-6 py-12 h-screen md:hidden">
        {loading ? (
          <div className="text-6xl font-bold text-center flex justify-center">
            Loading
          </div>
        ) : (
          <div className="flex flex-col items-center">
            <div className="hidden md:block">
              <img src="/images/landing-pages/logo.png" alt="logo" />
            </div>

            <img
              src={
                errorCheck === false
                  ? `/images/verification_password/success_validation.png`
                  : `/images/verification_password/failed_validation.png`
              }
              alt="checklist"
              className="mt-24"
            />
            <div className="mt-16 mb-8">
              {errorCheck === false ? (
                <h1 className="text-2xl font-bold">
                  Email Berhasil Terverifikasi!
                </h1>
              ) : (
                <h1 className="text-2xl font-bold">
                  Email Gagal Terverifikasi!
                </h1>
              )}
            </div>
            <div className="mb-8">
              <p className="text-lg text-center">{message}</p>
            </div>
            <button
              type="button"
              onClick={() => history.push('/')}
              className={`w-3/4 ${
                errorCheck === false ? 'bg-primary' : 'bg-error'
              } rounded-full py-3 text-white font-semibold text-xl mt-8`}
            >
              {' '}
              Halaman Utama
            </button>
          </div>
        )}
      </div>
      <div
        className="h-screen bg-no-repeat bg-cover hidden md:block"
        style={{
          backgroundImage:
            "url('images/verification_password/bg-opacity-blue.png')",
        }}
      >
        <div className="flex justify-center items-center h-full ">
          <div className="h-1/2 bg-white py-14 rounded-3xl w-1/2">
            {loading && (
              <div className="text-6xl font-bold text-center flex justify-center">
                Loading
              </div>
            )}

            {loading === false && errorCheck === false && (
              <div className="flex flex-col items-center justify-between h-full w-full">
                <img
                  src="images/verification_password/success_validation.png"
                  alt="logo-response"
                />
                <div className="flex flex-col items-center">
                  <h1 className="text-center mb-4 text-3xl font-bold">
                    Email Berhasil Terverifikasi!
                  </h1>
                  <p className="text-center text-md">{message}</p>
                </div>
                <button
                  onClick={() => history.push('/')}
                  className="w-1/2 bg-primary py-2 rounded-full text-2xl text-white font-semibold"
                >
                  Halaman Utama
                </button>
              </div>
            )}

            {loading === false && errorCheck === true && (
              <div className="flex flex-col items-center justify-between h-full w-full">
                <img
                  src="images/verification_password/failed_validation.png"
                  alt="logo-response"
                />
                <div className="flex flex-col items-center">
                  <h1 className="text-center mb-4 text-3xl font-bold">
                    Email Gagal Terverifikasi!
                  </h1>
                  <p className="text-center text-md">{message}</p>
                </div>
                <button
                  onClick={() => history.push('/')}
                  className="w-1/2 bg-error py-2 rounded-full text-2xl text-white font-semibold"
                >
                  Halaman Utama
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Verify;
