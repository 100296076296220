import React, { useState } from 'react';

function Harga() {
  const [tahun, setTahun] = useState(false);
  const data = {
    bulanan: {
      bisnis: '150.000',
      pro: '75.000',
    },
    tahunan: {
      bisnis: '1.800.000',
      pro: '900.000',
    },
  };
  return (
    <main>
      <section
        style={{
          backgroundImage: "url('./images/harga-pages/illustration-harga.png')",
        }}
        className="py-24 md:py-44 md:h-auto flex flex-col justify-start items-center bg-cover bg-no-repeat md:bg-autoheight bg-right-top md:bg-center"
      >
        <div className="flex flex-col justify-center items-center mt-8 md:mb-32 md:mt-0">
          <div className="text-2xl md:text-4xl font-bold text-white">
            <h1>Harga Bersahabat</h1>
          </div>

          <div className="md:my-10 text-xs md:text-lg w-3/4 flex text-center text-white">
            <p>
              Hemat 20% untuk langganan tahunan dengan kode voucher
              <span className="font-bold">#BerbagiDiskon.</span>
            </p>
          </div>

          <div className="w-2/5 md:w-1/4 mt-6 md:mt-12">
            <div
              className={`
                flex
                justify-around
                items-center
                ${!tahun ? 'bg-blue-500' : ''}
                py-3
                px-12
                md:px-6
                rounded-2xl
              `}
            >
              <input
                type="radio"
                id="bulan"
                name="waktu"
                value="bulan"
                className="mr-4"
                checked={!tahun && true}
                onClick={() => {
                  setTahun(false);
                }}
              />
              <label for="bulan" className="font-bold text-white text-xl">
                Bulanan
              </label>
            </div>
            <div
              className={`
                flex
                justify-around
                items-center
                ${tahun ? 'bg-blue-500' : ''}
                py-3
                px-6
                rounded-2xl
                md:mt-8
              `}
            >
              <input
                type="radio"
                id="tahun"
                name="waktu"
                value="tahun"
                className="mr-4"
                checked={tahun && true}
                onClick={() => setTahun(true)}
              />
              <label for="tahun" className="font-bold text-white text-xl">
                Tahunan
              </label>
            </div>
          </div>
        </div>
      </section>

      <section
        className=" bg-secondary md:bg-white md:rounded-none mb-16 md:mb-0"
        style={{
          borderBottomRightRadius: '4rem',
          borderBottomLeftRadius: '4rem',
        }}
      >
        <div className="w-10/12 mx-auto grid grid-cols-1 md:grid-cols-3 md:gap-x-16 gap-y-24 pb-16">
          {/* Standard */}
          <div className="-mt-24">
            <div
              className="
              flex flex-col
              justify-center
              items-center
              border-2
              rounded-2xl
              shadow-2xl
              px-12
              py-16
              bg-white
              md:pb-20
            "
            >
              <div className="my-4">
                <img src="./images/harga-pages/standard.png" alt="" />
              </div>

              <h1 className="my-4 text-2xl font-semibold">STANDARD</h1>

              <p className="mb-4 text-xl text-gray-500 text-center">
                Cocok untuk pemakaian pribadi.
              </p>

              <div
                className="
                bg-grey-200
                text-xl
                font-semibold
                rounded-3xl
                bg-gray-100
                w-4/5
                text-center
                py-2
                mt-8
              "
              >
                GRATIS
              </div>

              <div className="grid grid-cols-1 gap-y-8 my-16">
                <div className="flex items-center">
                  <img
                    src="images/harga-pages/checklist.png"
                    alt=""
                    className="mr-6 w-4"
                  />
                  <p className="text-justify">
                    Bisa digunakan untuk 10 outlet dengan lebih dari 100 produk
                    setiap outletnya
                  </p>
                </div>
                <div className="flex items-center">
                  <img
                    src="images/harga-pages/checklist.png"
                    alt=""
                    className="mr-6 w-4"
                  />
                  <p className="text-justify">
                    Transaksi realtime bisa didapatkan selama 1 bulan
                  </p>
                </div>
                <div className="flex items-center">
                  <img
                    src="images/harga-pages/checklist.png"
                    alt=""
                    className="mr-6 w-4"
                  />
                  <p className="text-justify">
                    Tersedia link Google Maps padakolom lokasi outlet
                  </p>
                </div>
              </div>
              <button className="border-4 border-black rounded-3xl w-4/5 py-3">
                Pilih Standard
              </button>
            </div>
          </div>

          {/* Bisnis */}
          <div className="md:-mt-48">
            <div
              className="
              flex flex-col
              justify-center
              items-center
              border-2
              rounded-2xl
              shadow-2xl
              px-12
              py-24
              bg-white
              md:pb-44
            "
            >
              <div className="my-4">
                <img src="./images/harga-pages/bisnis.png" alt="" />
              </div>

              <h1 className="my-4 text-3xl font-bold text-blue-800">BISNIS</h1>

              <p className="mb-4 text-xl text-gray-500 text-center">
                Cocok untuk pribadi, komunitas, UMKM, pengusaha, media promosi,
                maupun perusahaan
              </p>

              <div
                className="
                bg-grey-200
                text-xl
                font-semibold
                rounded-3xl
                bg-gray-100
                w-4/5
                text-center
                py-2
                mt-8
              "
              >
                Rp {tahun ? data.tahunan.bisnis : data.bulanan.bisnis}
              </div>
              <p className="text-center text-gray-500">
                per {tahun ? 'tahun' : 'bulan'}
              </p>

              <div className="grid grid-cols-1 gap-y-8 my-16">
                <div className="flex items-center">
                  <img
                    src="images/harga-pages/blue-checklist.png"
                    alt=""
                    className="mr-6 w-4 h-4"
                  />
                  <p className="text-justify">
                    Tidak terbatas dalam membuat outlet dan produk yang
                    diinginkan
                  </p>
                </div>
                <div className="flex items-center">
                  <img
                    src="images/harga-pages/blue-checklist.png"
                    alt=""
                    className="mr-6 w-4 h-4"
                  />
                  <p className="text-justify">
                    Mendapatkan semua fitur yang tersedia di BisaKasir
                  </p>
                </div>
                <div className="flex items-center justify-items-start-start">
                  <img
                    src="images/harga-pages/blue-checklist.png"
                    alt=""
                    className="mr-6 w-4 h-4"
                  />
                  <p className="text-justify">Layanan bantuan prioritas</p>
                </div>
              </div>
              <button
                className="
                border-4 border-secondary
                rounded-3xl
                w-4/5
                py-3
                text-white
                font-semibold
                bg-secondary
              "
              >
                Pilih Bisnis
              </button>
            </div>
          </div>
          {/* Pro */}
          <div className="md:-mt-24">
            <div
              className="
              flex flex-col
              justify-center
              items-center
              border-2
              rounded-2xl
              shadow-2xl
              px-12
              py-16
              bg-white
            "
            >
              <div className="my-4">
                <img src="./images/harga-pages/icon.png" alt="" />
              </div>

              <h1 className="my-4 text-2xl font-bold text-primary">PRO</h1>

              <p className="mb-4 text-xl text-gray-500 text-center">
                Cocok untuk pribadi dan para pengusaha yang baru mulai
              </p>

              <div
                className="
                bg-grey-200
                text-xl
                font-semibold
                rounded-3xl
                bg-gray-100
                w-4/5
                text-center
                py-2
                mt-8
              "
              >
                Rp {tahun ? data.tahunan.pro : data.bulanan.pro}
              </div>
              <p className="text-center text-gray-500">
                per {tahun ? 'tahun' : 'bulan'}
              </p>

              <div className="grid grid-cols-1 gap-y-8 my-16">
                <div className="flex items-center">
                  <img
                    src="./images/harga-pages/green-checklist.png"
                    alt=""
                    className="mr-6 w-4 h-4"
                  />
                  <p className="text-justify">
                    Bisa digunakan untuk 50 outlet dengan lebih dari 500 produk
                    setiap outletnya
                  </p>
                </div>
                <div className="flex items-center">
                  <img
                    src="./images/harga-pages/green-checklist.png"
                    alt=""
                    className="mr-6 w-4 h-4"
                  />
                  <p className="text-justify">
                    Mendapatkan semua fitur yang tersedia pada akun standar
                  </p>
                </div>
                <div className="flex items-center">
                  <img
                    src="./images/harga-pages/green-checklist.png"
                    alt=""
                    className="mr-6 w-4 h-4"
                  />
                  <p className="text-justify">
                    Tersedia fitur banner promotion dan special packages
                  </p>
                </div>
              </div>
              <button
                className="
                border-4 border-primary
                bg-primary
                text-white
                font-bold
                rounded-3xl
                w-4/5
                py-3
              "
              >
                Pilih Pro
              </button>
            </div>
          </div>
        </div>
      </section>

      <section
        style={{
          backgroundImage:
            "url('./images/harga-pages/perbandingan-fitur-bg.png')",
        }}
        className="h-48 md:h-64 bg-no-repeat bg-cover "
      >
        <div className="flex flex-col justify-center items-center h-full">
          <h1 className="text-white text-3xl md:text-4xl font-bold mb-4 md:mb-8">
            Perbandingan Fitur
          </h1>
          <p className="w-4/5 md:w-1/4 text-center text-white">
            Bandingkan dan tentukan paket yang cocok untuk kebutuhan Anda.
          </p>
        </div>
      </section>

      {/* ============== Perbandingan Harga  ================ */}
      <section className="md:mb-32 mt-16 w-11/12 md:w-10/12 mx-auto md:text-base text-xxs">
        <table className="text-center w-full border-collapse">
          <thead>
            <tr>
              <th className="text-left align-bottom leading-10 md:py-8">
                <h1 className="text-lg">Keterangan</h1>
                <p className="font-medium">Fitur Bisakasir</p>
              </th>
              <th></th>
              <th className="leading-8 md:py-12">
                <div className="hidden md:block">
                  <h1 className="md:text-lg text-white md:text-black">
                    STANDARD
                  </h1>
                </div>
                <div className="w-16 h-12 flex flex-col justify-center bg-gray-400 md:hidden">
                  <h1 className="text-white text-xxs">STANDARD</h1>
                </div>
                <p className="text-lg font-medium hidden md:inline">Gratis</p>
                <div className="justify-center my-4 hidden md:flex">
                  <img
                    src="./images/harga-pages/standard.png"
                    className="w-4 md:w-1/6"
                    alt="standard"
                  />
                </div>
                <div className="hidden md:inline">
                  <button className="w-4/5 border-4 border-black rounded-3xl">
                    Pilih Standard
                  </button>
                </div>
              </th>
              <th className="leading-8">
                <div className="hidden md:block">
                  <h1 className="md:text-lg text-white md:text-primary">PRO</h1>
                </div>
                <div className="h-12 w-24 flex flex-col justify-center bg-primary md:py-0 md:w-0  md:hidden">
                  <h1 className="text-white ">PRO</h1>
                </div>
                <p className="text-lg font-medium hidden md:inline">
                  Rp{' '}
                  {tahun
                    ? `${data.tahunan.pro}/tahun`
                    : `${data.bulanan.pro}/bulan`}
                </p>
                <div className="justify-center my-4 hidden md:flex">
                  <img
                    src="./images/harga-pages/icon.png"
                    className="w-4 md:w-1/6"
                    alt="pro"
                  />
                </div>
                <div className="hidden md:inline">
                  <button
                    className="
                    w-2/3
                    border-4
                    text-white
                    border-primary
                    bg-primary
                    rounded-3xl
                  "
                  >
                    Pilih Pro
                  </button>
                </div>
              </th>
              <th className="leading-8">
                <div className="hidden md:block">
                  <h1 className="text-xl text-secondary">BISNIS</h1>
                </div>
                <div className="h-12 w-24 flex flex-col justify-center bg-secondary md:py-0 md:w-0 md:hidden">
                  <h1 className="text-white">BISNIS</h1>
                </div>
                <p className="text-lg font-medium hidden md:inline">
                  Rp{' '}
                  {tahun
                    ? `${data.tahunan.bisnis}/tahun`
                    : `${data.bulanan.bisnis}/bulan`}
                </p>
                <div className="justify-center my-4 hidden md:flex">
                  <img
                    src="./images/harga-pages/bisnis.png"
                    className="w-4 md:w-1/6"
                    alt="bisnis"
                  />
                </div>
                <div className="hidden md:inline">
                  <button
                    className="
                  w-2/3
                  border-4
                  text-white
                  border-secondary
                  bg-secondary
                  rounded-3xl
                "
                  >
                    Pilih Bisnis
                  </button>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-gray-100">
              <td className="text-left py-4 pl-4">Jumlah Outlet</td>
              <td>
                <div className="w-full flex justify-center">
                  <img
                    src="./images/harga-pages/info.png"
                    className="w-4 md:w-1/6"
                    alt="info"
                  />
                </div>
              </td>
              <td>10</td>
              <td className="text-primary">50</td>
              <td className="text-secondary">Unlimited</td>
            </tr>
            <tr>
              <td className="text-left py-4 px-4">Jumlah Kategori</td>
              <td>
                <div className="w-full flex justify-center">
                  <img
                    src="./images/harga-pages/info.png"
                    className="w-4 md:w-1/6"
                    alt="info"
                  />
                </div>
              </td>
              <td>50</td>
              <td className="text-primary">500</td>
              <td className="text-secondary">Unlimited</td>
            </tr>
            <tr className="bg-gray-100">
              <td className="text-left py-4 px-4">Jumlah Produk</td>
              <td>
                <div className="w-full flex justify-center">
                  <img
                    src="./images/harga-pages/info.png"
                    className="w-4 md:w-1/6"
                    alt="info"
                  />
                </div>
              </td>
              <td>100</td>
              <td className="text-primary">1000</td>
              <td className="text-secondary">Unlimited</td>
            </tr>
            <tr>
              <td className="text-left py-4 px-4">Jumlah Kategori</td>
              <td>
                <div className="w-full flex justify-center">
                  <img
                    src="./images/harga-pages/info.png"
                    className="w-4 md:w-1/6"
                    alt="info"
                  />
                </div>
              </td>
              <td>1 Bulan</td>
              <td className="text-primary">3 Bulan</td>
              <td className="text-secondary">Selamanya</td>
            </tr>
            <tr className="bg-gray-100">
              <td className="text-left py-4 px-4">Inventory POS</td>
              <td>
                <div className="w-full flex justify-center">
                  <img
                    src="./images/harga-pages/info.png"
                    className="w-4 md:w-1/6"
                    alt="info"
                  />
                </div>
              </td>
              <td>
                <div className="flex justify-center">
                  <img
                    src="./images/harga-pages/checklist.png"
                    className="w-3 md:w-6"
                    alt=""
                  />
                </div>
              </td>
              <td className="text-primary">
                <div className="flex justify-center">
                  <img
                    src="./images/harga-pages/green-checklist.png"
                    className="w-3 md:w-6"
                    alt=""
                  />
                </div>
              </td>
              <td className="text-secondary">
                <div className="flex justify-center">
                  <img
                    src="./images/harga-pages/blue-checklist.png"
                    className="w-3 md:w-6"
                    alt=""
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td className="text-left py-4 px-4">Mini Website</td>
              <td>
                <div className="w-full flex justify-center">
                  <img
                    src="./images/harga-pages/info.png"
                    className="w-4 md:w-1/6"
                    alt="info"
                  />
                </div>
              </td>
              <td>
                <div className="flex justify-center">
                  <img
                    src="./images/harga-pages/checklist.png"
                    className="w-3 md:w-6"
                    alt=""
                  />
                </div>
              </td>
              <td className="text-primary">
                <div className="flex justify-center">
                  <img
                    src="./images/harga-pages/green-checklist.png"
                    className="w-3 md:w-6"
                    alt=""
                  />
                </div>
              </td>
              <td className="text-secondary">
                <div className="flex justify-center">
                  <img
                    src="./images/harga-pages/blue-checklist.png"
                    className="w-3 md:w-6"
                    alt=""
                  />
                </div>
              </td>
            </tr>
            <tr className="bg-gray-100">
              <td className="text-left py-4 px-4">Jadwal Sholat</td>
              <td>
                <div className="w-full flex justify-center">
                  <img
                    src="./images/harga-pages/info.png"
                    className="w-4 md:w-1/6"
                    alt="info"
                  />
                </div>
              </td>
              <td>
                <div className="flex justify-center">
                  <img
                    src="./images/harga-pages/checklist.png"
                    className="w-3 md:w-6"
                    alt=""
                  />
                </div>
              </td>
              <td className="text-primary">
                <div className="flex justify-center">
                  <img
                    src="./images/harga-pages/green-checklist.png"
                    className="w-3 md:w-6"
                    alt=""
                  />
                </div>
              </td>
              <td className="text-secondary">
                <div className="flex justify-center">
                  <img
                    src="./images/harga-pages/blue-checklist.png"
                    className="w-3 md:w-6"
                    alt=""
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td className="text-left py-4 px-4">Al-Qur'an Digital</td>
              <td>
                <div className="w-full flex justify-center">
                  <img
                    src="./images/harga-pages/info.png"
                    className="w-4 md:w-1/6"
                    alt="info"
                  />
                </div>
              </td>
              <td>
                <div className="flex justify-center">
                  <img
                    src="./images/harga-pages/checklist.png"
                    className="w-3 md:w-6"
                    alt=""
                  />
                </div>
              </td>
              <td className="text-primary">
                <div className="flex justify-center">
                  <img
                    src="./images/harga-pages/green-checklist.png"
                    className="w-3 md:w-6"
                    alt=""
                  />
                </div>
              </td>
              <td className="text-secondary">
                <div className="flex justify-center">
                  <img
                    src="./images/harga-pages/blue-checklist.png"
                    className="w-3 md:w-6"
                    alt=""
                  />
                </div>
              </td>
            </tr>
            <tr className="bg-gray-100">
              <td className="text-left py-4 px-4">Inventory Produk</td>
              <td>
                <div className="w-full flex justify-center">
                  <img
                    src="./images/harga-pages/info.png"
                    className="w-4 md:w-1/6"
                    alt="info"
                  />
                </div>
              </td>
              <td>
                <div className="flex justify-center"></div>
              </td>
              <td className="text-primary">
                <div className="flex justify-center">
                  <img
                    src="./images/harga-pages/green-checklist.png"
                    className="w-3 md:w-6"
                    alt=""
                  />
                </div>
              </td>
              <td className="text-secondary">
                <div className="flex justify-center">
                  <img
                    src="./images/harga-pages/blue-checklist.png"
                    className="w-3 md:w-6"
                    alt=""
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td className="text-left py-4 px-4">Manajemen Stok</td>
              <td>
                <div className="w-full flex justify-center">
                  <img
                    src="./images/harga-pages/info.png"
                    className="w-4 md:w-1/6"
                    alt="info"
                  />
                </div>
              </td>
              <td>
                <div className="flex justify-center"></div>
              </td>
              <td className="text-primary">
                <div className="flex justify-center">
                  <img
                    src="./images/harga-pages/green-checklist.png"
                    className="w-3 md:w-6"
                    alt=""
                  />
                </div>
              </td>
              <td className="text-secondary">
                <div className="flex justify-center">
                  <img
                    src="./images/harga-pages/blue-checklist.png"
                    className="w-3 md:w-6"
                    alt=""
                  />
                </div>
              </td>
            </tr>
            <tr className="bg-gray-100">
              <td className="text-left py-4 px-4">Penjadwalan</td>
              <td>
                <div className="flex justify-center">
                  <div
                    style={{ fontSize: '10px' }}
                    className="
                    font-bold
                    md:border-4
                    py-0.5
                    md:border-black
                    md:rounded-3xl
                    text-xs
                    md:w-2/3
                  "
                  >
                    coming soon
                  </div>
                </div>
              </td>
              <td>
                <div className="flex justify-center"></div>
              </td>
              <td className="text-primary">
                <div className="flex justify-center">
                  <img
                    src="./images/harga-pages/green-checklist.png"
                    className="w-3 md:w-6"
                    alt=""
                  />
                </div>
              </td>
              <td className="text-secondary">
                <div className="flex justify-center">
                  <img
                    src="./images/harga-pages/blue-checklist.png"
                    className="w-3 md:w-6"
                    alt=""
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td className="text-left py-4 px-4">Custom Domain</td>
              <td>
                <div className="flex justify-center">
                  <div
                    style={{ fontSize: '10px' }}
                    className="
                    font-bold
                    md:border-4
                    py-0.5
                    md:border-black
                    md:rounded-3xl
                    text-xs
                    md:w-2/3
                  "
                  >
                    coming soon
                  </div>
                </div>
              </td>
              <td>
                <div className="flex justify-center"></div>
              </td>
              <td className="text-primary">
                <div className="flex justify-center">
                  <img
                    src="./images/harga-pages/green-checklist.png"
                    className="w-3 md:w-6"
                    alt=""
                  />
                </div>
              </td>
              <td className="text-secondary">
                <div className="flex justify-center">
                  <img
                    src="./images/harga-pages/blue-checklist.png"
                    className="w-3 md:w-6"
                    alt=""
                  />
                </div>
              </td>
            </tr>
            <tr className="bg-gray-100">
              <td className="text-left py-4 px-4">Employee</td>
              <td>
                <div className="flex justify-center">
                  <div
                    style={{ fontSize: '10px' }}
                    className="
                    font-bold
                    md:border-4
                    py-0.5
                    md:border-black
                    md:rounded-3xl
                    text-xs
                    md:w-2/3
                  "
                  >
                    coming soon
                  </div>
                </div>
              </td>
              <td>
                <div className="flex justify-center"></div>
              </td>
              <td className="text-primary">
                <div className="flex justify-center">
                  <img
                    src="./images/harga-pages/green-checklist.png"
                    className="w-3 md:w-6"
                    alt=""
                  />
                </div>
              </td>
              <td className="text-secondary">
                <div className="flex justify-center">
                  <img
                    src="./images/harga-pages/blue-checklist.png"
                    className="w-3 md:w-6"
                    alt=""
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td className="text-left py-4 px-4">Management Aplikasi</td>
              <td>
                <div className="flex justify-center">
                  <div
                    style={{ fontSize: '10px' }}
                    className="
                    font-bold
                    md:border-4
                    py-0.5
                    md:border-black
                    md:rounded-3xl
                    text-xs
                    md:w-2/3
                  "
                  >
                    coming soon
                  </div>
                </div>
              </td>
              <td>
                <div className="flex justify-center"></div>
              </td>
              <td className="text-primary">
                <div className="flex justify-center"></div>
              </td>
              <td className="text-secondary">
                <div className="flex justify-center">
                  <img
                    src="./images/harga-pages/blue-checklist.png"
                    className="w-3 md:w-6"
                    alt=""
                  />
                </div>
              </td>
            </tr>
            <tr className="bg-gray-100">
              <td className="text-left py-4 px-4">Akutansi Lengkap</td>
              <td>
                <div className="flex justify-center">
                  <div
                    style={{ fontSize: '10px' }}
                    className="
                    font-bold
                    md:border-4
                    py-0.5
                    md:border-black
                    md:rounded-3xl
                    text-xs
                    md:w-2/3
                  "
                  >
                    coming soon
                  </div>
                </div>
              </td>
              <td>
                <div className="flex justify-center"></div>
              </td>
              <td className="text-primary">
                <div className="flex justify-center"></div>
              </td>
              <td className="text-secondary">
                <div className="flex justify-center">
                  <img
                    src="./images/harga-pages/blue-checklist.png"
                    className="w-3 md:w-6"
                    alt=""
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="w-full flex flex-row justify-center md:justify-between md:mt-16 items-center">
          <div className="hidden md:inline">
            <img
              src="./images/harga-pages/logo-perbandingan-harga.png"
              className="w-32"
              alt=""
            />
          </div>
          <div>
            <h1 className="my-10 md:my-0 md:text-5xl text-primary font-bold">
              Pelajari Fitur Selengkapnya
            </h1>
          </div>
          <div className="hidden md:inline">
            <img
              src="./images/harga-pages/logo-perbandingan-harga-2.png"
              className="w-32"
              alt=""
            />
          </div>
        </div>
      </section>
      <section
        style={{
          backgroundImage:
            "url('./images/harga-pages/coba-sekarang-background.png')",
          backgroundSize: '150% 100%',
        }}
        className="h-32 md:h-80 md:bg-contain bg-no-repeat bg-center"
      >
        <div
          className="
            flex flex-col
            justify-center
            items-start
            h-full
            w-10/12
            mx-auto
          "
        >
          <h1 className="text-white text-xl md:text-3xl font-black mb-4">
            Coba Sekarang, Gratis!
          </h1>
          <button className="font-bold text-xs md:text-base px-4 py-1 md:px-24 md:py-2 text-white bg-primary rounded-3xl">
            Daftar Gratis
          </button>
        </div>
      </section>
    </main>
  );
}

export default Harga;
