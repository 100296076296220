import React from 'react';
import { Link } from 'react-router-dom';

function Register() {
  return (
    <main>
      <section
        className="
          w-10/12
          mx-auto
          grid grid-cols-1
          md:grid-cols-2
          justify-items-center
          items-center
          py-24
        "
      >
        <div className="hidden md:inline">
          <img
            src="images/register-pages/illustration-register.png"
            alt="Register Illustration"
          />
        </div>
        <div className="w-11/12">
          <div className="mb-12 md:mb-0">
            <h1 className="text-4xl font-bold">Daftar</h1>
            <p>
              Sudah punya akun?
              <Link className="text-primary underline" to="/login">
                &nbsp; Log In.
              </Link>
            </p>
          </div>
          <div className="flex flex-col w-full my-2 text-sm md:text-base">
            <label htmlFor="email" className="my-2">
              Nama Lengkap
            </label>
            <input
              type="text"
              placeholder="Isi Nama Lengkap Anda"
              name=""
              id=""
              className="w-full py-2 px-4 border-2 rounded-md"
            />
          </div>
          <div className="flex flex-col w-full my-2 text-sm md:text-base">
            <label htmlFor="username" className="my-2">
              Buat Username
            </label>
            <input
              type="text"
              placeholder="Buat Username"
              name=""
              id=""
              className="w-full py-2 px-4 border-2 rounded-md"
            />
          </div>
          <div className="flex flex-col w-full my-2 text-sm md:text-base">
            <label htmlFor="email" className="my-2">
              Masukan E-mail Anda
            </label>
            <input
              type="text"
              placeholder="example@domain.com"
              name=""
              id=""
              className="w-full py-2 px-4 border-2 rounded-md"
            />
          </div>
          <div className="flex flex-col w-full my-2 text-sm md:text-base">
            <label htmlFor="password" className="my-2">
              Masukan Nomor HP Anda
            </label>
            <input
              type="text"
              placeholder="08xxxxxxxx"
              name=""
              id=""
              className="w-full py-2 px-4 border-2 rounded-md"
            />
          </div>
          <div className="flex flex-col w-full my-2 text-sm md:text-base">
            <label htmlFor="password" className="my-2">
              Buat Password
            </label>
            <input
              type="password"
              placeholder="Buat Password"
              name=""
              id=""
              className="w-full py-2 px-4 border-2 rounded-md"
            />
          </div>
          <div className="flex flex-col w-full my-2 text-sm md:text-base">
            <label htmlFor="password" className="my-2">
              Konfirmasi Password
            </label>
            <input
              type="password"
              placeholder="Tulis Ulang Password Anda"
              name=""
              id=""
              className="w-full py-2 px-4 border-2 rounded-md"
            />
          </div>
          <div
            className="
              flex flex-row
              justify-between
              my-4
              items-center
              text-sm
              md:text-base
            "
          >
            <div className="flex flex-col w-1/2">
              <label htmlFor="password" className="my-2">
                Kode Referensi
              </label>
              <input
                type="password"
                placeholder="Tulis Kode"
                name=""
                id=""
                className="w-full py-2 px-4 border-2 rounded-md"
              />
            </div>
            <div className="flex flex-col w-1/2 mx-2">
              <label htmlFor="" className="mb-2 md:mb-0">
                captcha
              </label>
              <img src="images/register-pages/image 5.png" alt="captcha" />
            </div>
          </div>
          <div className="flex justify-center items-center my-4">
            <input type="checkbox" />
            <label htmlFor="" className="text-sm ml-4">
              Saya setuju dengan &nbsp;
              <Link className="underline text-primary" to="/">
                Syarat dan Ketentuan
              </Link>
              &nbsp; layanan bisakasir
            </label>
          </div>
          <div className="w-full flex justify-center">
            <button
              className="
                border-2 border-primary
                rounded-full
                py-3
                md:py-2
                w-2/3
                md:w-full
                bg-primary
                text-white
                font-bold
              "
            >
              Daftar
            </button>
          </div>
          <p className="my-6 text-center text-gray-400">
            atau daftar dengan akun
          </p>
          <div className="w-full flex justify-center items-center text-xs md:text-base">
            <button
              className="
                flex
                border-2
                rounded-full
                items-center
                px-3
                py-2
                md:px-5 md:py-2
              "
            >
              <img
                src="images/login-pages/google.png"
                className="md:mr-2"
                alt=""
              />
              <p>Google</p>
            </button>
            <button
              className="
                flex
                border-2
                rounded-full
                items-center
                px-3
                py-2
                md:px-5 md:py-2
                mx-4
              "
            >
              <img
                src="images/login-pages/facebook.png"
                className="md:mr-2"
                alt=""
              />
              <p>Facebook</p>
            </button>
            <button
              className="
                flex
                border-2
                rounded-full
                items-center
                px-3
                py-2
                md:px-5 md:py-2
              "
            >
              <img
                src="images/login-pages/instagram.png"
                className="md:mr-2"
                alt=""
              />
              <p>Instagram</p>
            </button>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Register;
