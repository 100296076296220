import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="py-16 bg-gray-100">
      <div className="w-10/12 md:w-8/12 mx-auto text-xs md:text-base flex flex-col items-center">
        <img
          className="self-start -ml-6"
          src="images/landing-pages/logo.png"
          alt="logo"
        />
        <div
          className="
            grid grid-cols-2
            md:grid-cols-3
            self-start
            w-full
            gap-x-16 gap-y-16
            md:gap-y-0
          "
        >
          <div className="">
            <div className="flex items-center my-4">
              <p className="leading-7">
                Solusi terbaik tingkatkan Penjualan kamu dengan Mudah dan
                Efektif. Dapatkan Info menarik melalui
              </p>
            </div>
            <div className="flex">
              <img
                src="images/footer/facebook-logo.png"
                className="w-6 h-6"
                alt="facebook"
              />
              <img
                src="images/footer/twitter-logo.png"
                className="w-6 h-6 mx-2"
                alt="facebook"
              />
              <img
                src="images/footer/instagram-logo.png"
                className="w-6 h-6"
                alt="facebook"
              />
            </div>
          </div>
          <div className="flex justify-center">
            <div className="mr-6">
              <ul className="">
                <li className="text-primary my-3">
                  <Link to="/">Home</Link>
                </li>
                <li className="text-primary my-3">
                  <Link to="/">Services</Link>
                </li>
                <li className="text-primary my-3">
                  <Link to="/">Daftar Produk</Link>
                </li>
                <li className="text-primary my-3">
                  <Link to="/">Syarat dan Ketentuan</Link>
                </li>
              </ul>
            </div>
            <div className="ml-6">
              <ul>
                <li className="text-primary my-3">
                  <Link to="/terms">Privacy</Link>
                </li>
                <li className="text-primary my-3">
                  <Link to="/">API</Link>
                </li>
                <li className="text-primary my-3">
                  <Link to="/">Tentang</Link>
                </li>
              </ul>
            </div>
          </div>

          {/* Mobile */}
          <div className="md:hidden">
            <div className="my-2 flex items-center">
              <img
                src="images/footer/phone-icon.png"
                className="mr-4 w-4 h-4"
                alt="phone-icon"
              />
              <p>+62 21 2278 5123</p>
            </div>
            <div className="my-2 flex items-center">
              <img
                src="images/footer/phone-icon.png"
                className="mr-4 w-4 h-4"
                alt="phone-icon"
              />
              <p>+62 816 226 755</p>
            </div>
            <div className="my-2 flex items-center">
              <img
                src="images/footer/email-icon.png"
                className="mr-4 w-4 h-3"
                alt="email-icon"
              />
              <p>support@bisatopup.co.id</p>
            </div>
          </div>

          <div className="flex flex-col">
            <p className="font-bold">PT. AMANAH KARYA INDONESIA</p>
            <p className="my-3 leading-6">
              Ruko Griya Cakra, Jl. Rtm Jl. Klp. Dua Raya No.36, Tugu, Kec.
              Cimanggis, Kota Depok, Jawa Barat 16451
            </p>
            <div className="my-2 hidden md:flex items-center">
              <img
                src="images/footer/phone-icon.png"
                className="w-4 h-4 mr-4"
                alt="phone-icon"
              />
              <p>+62 21 2278 5123</p>
            </div>
            <div className="my-2 hidden md:flex items-center">
              <img
                src="images/footer/phone-icon.png"
                className="w-4 h-4 mr-4"
                alt="phone-icon"
              />
              <p>+62 816 226 755</p>
            </div>
            <div className="my-2 hidden md:flex items-center">
              <img
                src="images/footer/email-icon.png"
                className="w-4 h-4 mr-4"
                alt="email-icon"
              />
              <p>support@bisatopup.co.id</p>
            </div>
          </div>
        </div>
        <div className="mt-16">
          <p className="">Copyright @ 2021 PT. Amanah Karya Indonesia</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
