import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import BASE_URL from '../../config/url';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ForgetPassword() {
  const [input, setInput] = useState({
    password: '',
    password_confirmation: '',
  });
  const query = useQuery();
  const history = useHistory();
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(null);
  const [dataError, setDataError] = useState(null);

  const submit = async () => {
    console.log(input.password, input.password_confirmation);
    try {
      var values = {
        password: input.password,
        password_confirmation: input.password_confirmation,
        token: query.get('token'),
        email: query.get('email'),
      };
      setMessage(null);
      const response = await axios.post(`${BASE_URL}/password/reset`, values);
      setError(false);
      let message = response.data.message;
      setMessage(message);
      setInput({
        password: '',
        password_confirmation: '',
      });
      history.push('/reset-password/success');
    } catch ({ response }) {
      setError(true);
      let message = response.data.message;
      let errMessage = response.data.errors;
      if (errMessage) {
        setDataError(errMessage);
      }
      if (message) {
        setMessage(message);
      } else {
        setMessage('Connection error');
      }
    }
  };

  const changeHandler = e => {
    switch (e.target.name) {
      case 'password': {
        setInput({ ...input, password: e.target.value });
        break;
      }
      case 'password_confirmation': {
        setInput({ ...input, password_confirmation: e.target.value });
        break;
      }
      default:
        break;
    }
  };

  return (
    <div>
      <div className="flex flex-col items-center px-6 py-12 h-screen md:hidden">
        <div className="hidden md:block">
          <img src="/images/landing-pages/logo.png" alt="logo" />
        </div>

        <div className="mt-16 mb-8 self-start">
          <h1 className="text-2xl font-bold">Reset Password</h1>
        </div>
        {message && error === true && (
          <div className="my-2 w-full bg-error px-8 py-4 rounded-xl">
            <p className="text-xl text-white font-bold">Error</p>
            <p className="text-lg text-white">{message}</p>
          </div>
        )}
        <div className="w-full mt-8">
          <div className="w-full flex flex-col">
            <label htmlFor="">Password Baru</label>
            <input
              className={`w-full my-2 px-6 py-2 border-2 rounded-lg ${
                dataError && dataError.password
                  ? 'border-error'
                  : 'border-gray-300'
              }`}
              placeholder="Buat password baru"
              type="password"
              name="password"
              onChange={changeHandler}
            />
            {dataError && dataError.password && (
              <p className="text-error">{dataError.password[0]}</p>
            )}
          </div>
          <div className="w-full flex flex-col mt-6">
            <label htmlFor="">Konfirmasi Password</label>
            <input
              className={`w-full my-2 px-6 py-2 border-2 border-gray-300 rounded-lg`}
              placeholder="Ketik ulang password baru"
              type="password"
              name="password_confirmation"
              onChange={changeHandler}
            />
          </div>
        </div>
        <button
          type="button"
          onClick={submit}
          className="w-3/4 bg-primary rounded-full py-3 text-white font-semibold text-xl mt-8"
        >
          {' '}
          SUBMIT
        </button>
      </div>
      <div
        className="h-screen bg-no-repeat bg-cover hidden md:block"
        style={{
          backgroundImage:
            "url('images/verification_password/bg-opacity-blue.png')",
        }}
      >
        <div className="flex justify-center items-center h-full ">
          <div className=" bg-white py-10 mt-16 px-20 rounded-3xl w-2/5">
            <h1 className="text-3xl font-bold mb-4 self-start">
              Reset Password
            </h1>
            {message && error === true && (
              <div className="my-2 w-full bg-error px-8 py-4 rounded-xl">
                <p className="text-xl text-white font-bold">Error</p>
                <p className="text-lg text-white">{message}</p>
              </div>
            )}
            <form className="w-full h-full flex flex-col justify-start items-center">
              <div className="w-full">
                <div className="flex flex-col mt-2 ">
                  <label htmlFor="password" className="text-lg">
                    Password Baru
                  </label>
                  <input
                    className={`w-full my-2 px-6 py-2 border-2 border-gray-300 rounded-lg ${
                      dataError && dataError.password
                        ? 'border-error'
                        : 'border-gray-300'
                    }`}
                    placeholder="Buat password baru"
                    type="password"
                    name="password"
                    onChange={changeHandler}
                  />
                  {dataError && dataError.password && (
                    <p className="text-error">{dataError.password[0]}</p>
                  )}
                </div>
                <div className="flex flex-col mb-6 mt-3 ">
                  <label htmlFor="password_confirmation" className="text-lg">
                    Konfirmasi Password
                  </label>
                  <input
                    placeholder="Ketik ulang password baru"
                    className="w-full my-2 px-6 py-2 border-2 border-gray-300 rounded-lg"
                    type="password"
                    name="password_confirmation"
                    onChange={changeHandler}
                  />
                </div>
              </div>
              <button
                type="button"
                onClick={submit}
                className="w-3/4 bg-primary rounded-full py-3 text-white font-semibold text-xl mt-8"
              >
                {' '}
                SUBMIT
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;
