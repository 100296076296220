import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom';
import Nav from './Nav';
import Footer from './Footer';
import Core from './Core';
import ScrollToTop from '../utils/ScrollToTop';



function Main() {
    return (
      <React.Fragment>
        <Router>
          <ScrollToTop />
          <Nav />
          <Core />
          <Footer />
        </Router>
      </React.Fragment>
    );
}

export default Main
