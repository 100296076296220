import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Nav() {
  const [openNav, SetOpenNav] = useState(false);

  return (
    <React.Fragment>
      <header
        className={`flex flex-col  fixed inset-x-0 bg-white ${
          openNav && 'h-screen'
        }`}
      >
        <div className="flex py-4 px-6 justify-between border-b-2 border-gray-200 w-full items-center">
          <button onClick={() => SetOpenNav(!openNav)}>
            {openNav ? (
              <img
                src="/images/header/cross-icon.png"
                alt="hamburger-icon"
                className="w-4 h-4"
              />
            ) : (
              <img
                src="/images/header/hamburger-icon.png"
                alt="hamburger-icon"
                className="w-6 h-4"
              />
            )}
          </button>
          <div>
            <Link to="/" onClick={() => SetOpenNav(!openNav)}>
              <img
                src="/images/landing-pages/logo.png"
                alt="logo"
                className="w-32 ml-8"
              />
            </Link>
          </div>
          <Link to="/register" onClick={() => SetOpenNav(false)}>
            <div>
              <p className="text-primary">Daftar Gratis</p>
            </div>
          </Link>
        </div>
        <div className={`h-full  ${openNav ? 'block' : 'hidden'}`}>
          <Link to="/harga" onClick={() => SetOpenNav(!openNav)}>
            <div className="px-8 py-4 flex justify-between items-center bg-gray-100">
              <p className="text-xl">Harga</p>
              <img
                src="/images/header/arrow-icon.png"
                className="w-3 h-3"
                alt="arrow"
              />
            </div>
          </Link>
          <Link to="#" onClick={() => SetOpenNav(!openNav)}>
            <div className="px-8 py-4 flex justify-between items-center">
              <p className="text-xl">Fitur</p>
              <img
                src="/images/header/arrow-icon.png"
                className="w-3 h-3"
                alt="arrow"
              />
            </div>
          </Link>
          <div className="px-8 py-4 flex justify-between items-center bg-gray-100">
            <p className="text-xl">Blog</p>
            <img
              src="/images/header/arrow-icon.png"
              className="w-3 h-3"
              alt="arrow"
            />
          </div>
          <div className="px-8 py-4 flex justify-between items-center">
            <p className="text-xl">Tentang Kami</p>
            <img
              src="/images/header/arrow-icon.png"
              className="w-3 h-3"
              alt="arrow"
            />
          </div>
          <Link to="/login" onClick={() => SetOpenNav(!openNav)}>
            <div className="px-8 py-4 flex justify-between items-center bg-gray-100">
              <p className="text-xl text-primary">Log in</p>
              <img
                src="/images/header/arrow-icon.png"
                className="w-3 h-3"
                alt="arrow"
              />
            </div>
          </Link>
          <Link to="/register" onClick={() => SetOpenNav(!openNav)}>
            <div className="px-8 py-4 flex justify-between items-center">
              <p className="text-xl text-primary">Daftar Gratis</p>
              <img
                src="/images/header/arrow-icon.png"
                className="w-3 h-3"
                alt="arrow"
              />
            </div>
          </Link>
        </div>
      </header>
      <header className="justify-between items-center py-4 fixed inset-x-0 hidden md:inline bg-white">
        <div className="flex flex-row w-full mx-auto justify-between max-w-screen-xl">
          <div className="flex flex-row justify-between items-center">
            <Link to="/">
              <div>
                <img
                  src="/images/landing-pages/logo.png"
                  alt="logo-bisakasir"
                />
              </div>
            </Link>
            <nav className="mx-10">
              <ul className="flex flex-row">
                <Link to="/harga">
                  <li className="mx-4">Harga</li>
                </Link>
                <li className="mx-4">Fitur</li>
                <li className="mx-4">Blog</li>
                <li className="mx-4">Tentang Kami</li>
              </ul>
            </nav>
          </div>
          <div className="hidden md:inline">
            <Link to="/login">
              <button
                className="
              md:inline
              mx-4
              border-2
              rounded-3xl
              border-primary
              px-9
              py-1
              font
              text-primary
              font-semibold
            "
              >
                Log in
              </button>
            </Link>
            <Link to="/register">
              <button
                className="
            mx-4
            border-2
            rounded-3xl
            border-primary
            px-5
            py-1
            bg-primary
            text-white
            font-medium
            "
              >
                Daftar Gratis
              </button>
            </Link>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
}

export default Nav;
