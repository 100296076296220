import React from 'react';
import { useHistory } from 'react-router-dom';

function NotFound() {
  const history = useHistory();

  return (
    <div className="h-screen bg-secondary flex flex-col justify-center items-center">
      <div className="w-2/3">
        <img src="images/not_found/illustration.png" alt="404 Not Foun" />
      </div>
      <div className="mt-6">
        <h1 className="text-white text-2xl font-bold text-center">Ooops...</h1>
        <p className="text-white text-md">Halaman yang anda cari tidak ada</p>
      </div>
      <div className="mt-8">
        <button
          onClick={() => history.push('/')}
          className="bg-primary px-8 py-2 rounded-full text-white font-bold"
        >
          Kembali ke Homepage
        </button>
      </div>
    </div>
  );
}

export default NotFound;
