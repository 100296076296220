import React from 'react';

function Terms() {
  return (
    <div className="flex flex-col md:flex-row w-11/12 md:w-10/12 mx-auto items-center md:h-screen py-16 md:pt-0">
      <div className="md:w-5/12">
        <img
          src="images/login-pages/Illustration-login.png"
          alt="Login Illustration"
        />
      </div>
      <div className="md:w-7/12 mx-12">
        <h1 className="font-bold text-xl my-4 md:text-3xl">Kebijakan Privasi</h1>
        <div>
          <div className="max-h-96 overflow-y-scroll prose">
            <div className="my-4">
              <p className="font-bold mb-4">
                Assalamualaikum Warohmatullohi Wabarokatuh,
              </p>
              <p className="mb-4">
                Informasi berikut ini dibuat oleh PT. Amanah Karya Indonesia
                terkait penggunaan produk Bisakasir.id dan Pengguna disarankan
                membaca dengan seksama karena dapat berdampak kepada hak dan
                kewajiban Pengguna selama menggunakan produk Bisakasir.id.
              </p>
              <p>
                Syarat & Ketentuan ini merupakan bentuk kesepakatan yang
                dituangkan dalam sebuah perjanjian yang sah antara Pengguna
                dengan PT. Amanah Karya Indonesia. Jika pengguna tidak
                menyetujui salah satu, sebagian, atau seluruh isi Syarat &
                ketentuan, maka pengguna tidak diperkenankan menggunakan layanan
                aplikasi Bisakasir.id. Dengan mendaftar dan/atau menggunakan
                produk Bisakasir.id maka pengguna dianggap telah membaca,
                mengerti, memahami dan menyetujui semua isi yang terlampir pada
                halaman Syarat & Ketentuan.
              </p>
            </div>
            <div className="my-4">
              <p className="font-bold -mb-2">Definisi</p>
              <p className="mb-4">
                Semua istilah yang didefinisikan dan ditekankan dalam Syarat dan
                Ketentuan ini memiliki makna tertentu, sesuai dengan penjelasan
                di bawah ini:
              </p>
              <ul>
                <li>
                  Bisakasir.id adalah suatu sistem yang dapat mengumpulkan
                  berbagai tautan informasi dan dikumpulkan dalam satu tautan.
                </li>
                <li>
                  Pengguna adalah pihak yang menggunakan layanan produk
                  Bisakasir.id, termasuk namun tidak terbatas pada pembeli,
                  penjual, dan pihak lain yang memiliki kepentingan menggunakan
                  produk Bisakasir.id
                </li>
                <li>
                  Istilah pada Bisakasir.id atau "Kami" merujuk kepada pemilik
                  Situs Web yang kantornya beralamat di:
                </li>
                <li className="font-bold">
                  Ruko Griya Cakra, Jl. Rtm Jl. Klp. Dua Raya No.36, Tugu, Kec.
                  Cimanggis, Kota Depok, Jawa Barat 16451
                </li>
                <li>
                  Istilah "Anda" mengacu pada pengguna atau pemirsa Situs Web
                  Kami.
                </li>
                <li>
                  Akun mengacu pada proses yang Anda lakukan yaitu menyelesaikan
                  proses pendaftaran di situs web Bisakasir.id yang didalamnya
                  terdapat kumpulan informasi yang Anda buat.
                </li>
                <li>
                  Biaya Langganan Akun merupakan suatu nominal yang Kami
                  tawarkan kepada Anda untuk dapat menggunakan fasilitas
                  tertentu yang tersedia dalam produk Bisakasir.id.
                </li>
                <li>
                  Layanan berarti setiap layanan, program, jasa, produk, fitur,
                  sistem, fasilitas dan/atau jasa yang disediakan dan/atau
                  ditawarkan dalam atau melalui situs web Bisakasir.id.
                </li>
                <li>
                  Layanan Pelanggan adalah fungsi layanan bantuan untuk pengguna
                  yang memerlukan suatu informasi yang dapat dihubungi melalui
                  panggilan telephone, chat atau e-mail.
                </li>
                <li>
                  Syarat dan Ketentuan berarti suatu informasi berupa Syarat dan
                  Ketentuan yang berlaku pada situ web Bisakasir.id yang
                  memungkinkan adanya setiap perubahan, penambahan, penggantian,
                  penyesuaian dan/atau modifikasinya yang dibuat dari waktu ke
                  waktu.
                </li>
                <li>
                  Transaksi merupakan sebuah aktifitas atau kegiatan yang
                  dilakukan oleh Kami Bersama Anda dalam penggunaan Layanan atau
                  fitur - fitur tertentu produk Bisakasir.id
                </li>
              </ul>
            </div>
            <div>
              <p className="font-bold -mb-2">Ketentuan Penggunaan Situs Web</p>
              <ul>
                <li>
                  Isi halaman-halaman Situs Web ini adalah untuk memberikan
                  informasi umum pada Anda dan hanya untuk penggunaan saja. Isi
                  halaman Situs Web dapat berubah tanpa pemberitahuan.
                </li>
                <li>
                  Baik Kami maupun pihak ketiga tidak memberikan garansi atau
                  jaminan apapun mengenai keakuratan, ketepatan waktu, kinerja,
                  kelengkapan atau kesesuaian informasi dan materi yang
                  ditemukan atau ditawarkan di Situs Web ini untuk tujuan
                  tertentu. Anda mengakui bahwa informasi dan materi tersebut
                  mungkin berisi ketidakakuratan atau kesalahan dan Kami secara
                  tegas memberikan pengecualian tanggung jawab atas
                  ketidakakuratan atau kesalahan apapun sejauh yang diizinkan
                  oleh hukum.
                </li>
                <li>
                  Penggunaan informasi atau materi di Situs Web ini sepenuhnya
                  merupakan risiko Anda sendiri dan itu bukan tanggung jawab
                  Kami. Tanggung jawab Anda untuk memastikan bahwa setiap
                  produk, layanan, atau informasi yang tersedia melalui Situs
                  Web ini memenuhi persyaratan khusus Anda.
                </li>
                <li>
                  Situs Web ini berisi materi yang dimiliki oleh atau
                  dilisensikan kepada Kami. Materi ini tidak terbatas hanya pada
                  sistem, desain, tata letak, tampilan dan grafik.
                </li>
                <li>
                  Dilarang memperbanyak selain sesuai dengan pemberitahuan hak
                  cipta, yang merupakan bagian dari syarat dan ketentuan ini.
                </li>
                <li>
                  Semua merek dagang yang dihasilkan di Situs Web ini, yang
                  bukan milik, atau dilisensikan kepada operator, diakui di
                  Situs Web ini.
                </li>
                <li>
                  Penggunaan yang tidak sah dari Situs Web ini dapat menimbulkan
                  klaim atas kerusakan dan/atau menjadi tindak pidana.
                </li>
                <li>
                  Seiring berjalannya waktu, Situs Web ini juga dapat
                  menyertakan tautan ke Situs Web lain. Tautan ini disediakan
                  demi kenyamanan Anda untuk memberikan informasi lebih lanjut.
                  Namun ini tidak menAndakan bahwa Kami mendukung Situs Web
                  tersebut. Kami tidak bertanggung jawab atas isi dari Situs Web
                  tertaut.
                </li>
                <li>
                  Penggunaan Anda atas Situs Web ini dan setiap konflik yang
                  timbul dari penggunaan Situs Web hormat pada Undang-undang
                  Informasi dan Transaksi Elektronik atau Undang Undang nomor 11
                  tahun 2008 atau UU ITE.
                </li>
                <li>
                  Kami menggunakan Cookie agar Situs Web ini berguna sebagaimana
                  mestinya. Seperti situs web lainnya, Cookie ini digunakan
                  untuk menyimpan informasi termasuk preferensi pengunjung, dan
                  halaman-halaman di situs web yang diakses atau dikunjungi
                  pengunjung. Informasi ini digunakan untuk mengoptimalkan
                  pengalaman pengguna dengan menyesuaikan konten halaman web
                  Kami berdasarkan jenis browser pengunjung dan / atau informasi
                  lainnya.
                </li>
              </ul>
            </div>
            <div>
              <p className="font-bold">Kebijakan Privasi</p>
              <p>
                Bisakasir.id memperlakukan privasi Anda dengan sungguh-sungguh.
                Bacalah uraian berikut untuk mengetahui lebih jauh mengenai
                kebijakan privasi Kami
              </p>
              <ul>
                <li>
                  Dengan menggunakan layanan Kami, Anda memercayakan informasi
                  Anda kepada Kami. Kami paham bahwa melindungi informasi Anda
                  dan memberikan kontrol kepada Anda adalah tanggung jawab yang
                  besar dan memerlukan kerja keras.
                </li>
                <li>
                  Kebijakan Privasi ini bertujuan untuk membantu Anda memahami
                  informasi yang Kami kumpulkan, alasan Kami mengumpulkannya,
                  dan cara untuk memperbarui, mengelola, mengekspor, dan
                  menghapus informasi Anda.
                </li>
              </ul>
              <p className="font-bold text-center">
                “Kebijakan Berlaku terhitung sejak 31 September 2021”
              </p>
            </div>
            <div>
              <p className="font-bold">
                Yang Dicakup oleh Kebijakan Privasi ini
              </p>
              <ul>
                <li>
                  Kebijakan Privasi ini mencakup perlakuan Kami atas informasi
                  yang dapat mengidentifikasi diri (data pribadi) Anda saat
                  sedang berada di situs Bisakasir.id dan saat Anda menggunakan
                  layanan Kami. Kebijakan ini juga mencakup perlakukan Kami atas
                  data pribadi yang digunakan bersama oleh Kami.
                </li>
                <li>
                  Kebijakan ini tidak berlaku pada praktik perusahaan yang bukan
                  grup unit usaha milik Kami atau di luar kendali Kami atau pada
                  orang-orang yang bukan karyawan Kami atau di luar pengelolaan
                  Kami.
                </li>
              </ul>
            </div>
            <div>
              <p className="font-bold">Pengumpulan dan Penggunaan Informasi</p>
              <ul>
                <li>
                  Saat Anda mendaftar pada Bisakasir.id Kami menanyakan nama,
                  alamat email, alamat tempat tinggal, tanggal lahir, kode pos,
                  dan nomor telepon. Setelah Anda mendaftar dan masuk ke layanan
                  Kami, Anda bukanlah orang yang anonim bagi Kami.
                </li>
                <li>
                  Kami menggunakan informasi untuk memenuhi permintaan Anda akan
                  produk atau layanan tertentu dan untuk menghubungi Anda
                  mengenai tawaran khusus ataupun produk terbaru.
                </li>
              </ul>
            </div>
            <div>
              <p>Perubahan terhadap Kebijakan Privasi ini</p>
              <ul>
                <li>
                  Kami dapat mengubah kebijakan ini dari waktu ke waktu. Jika
                  Kami melakukan perubahan besar dalam cara penggunaan data
                  pribadi Anda, Kami akan memberi tahu Anda dengan memasang
                  pengumuman yang tampak jelas di halaman Kami maupun mengirim
                  email pada alamat yang terdaftar. Anda setuju bahwa pemasangan
                  pengumuman itu sudah cukup dan memadai untuk memberi tahu Anda
                  mengenai perubahan tersebut.
                </li>
                <li>
                  Anda setuju bahwa dengan terus menggunakan produk atau layanan
                  Kami setelah dilakukannya perubahan tersebut, Anda menerima
                  dan setuju untuk terikat dengan ketentuan dalam kebijakan yang
                  baru.
                </li>
              </ul>
            </div>
            <div>
              <p className="font-bold">Pengungkapan informasi Anda</p>
              <p>
                Kami dapat mengungkapkan informasi pribadi Anda kepada pihak
                ketiga:
              </p>
              <ul>
                <li>
                  Apabila Kami menjual atau membeli suatu bisnis atau aset,
                  dimana, dalam hal ini, Kami dapat mengungkapkan data pribadi
                  Anda kepada calon penjual atau pembeli dari bisnis atau aset
                  tersebut.
                </li>
                <li>
                  Apabila Kami berkewajiban mengungkapkan atau berbagi data
                  pribadi Anda dalam upaya mematuhi kewajiban hukum atau dalam
                  upaya memberlakukan atau menerapkan syarat-syarat penggunaan
                  Kami dan perjanjian-perjanjian lain; atau untuk melindungi
                  hak, properti, atau keselamatan Bisakasir.id, pelanggan Kami,
                  atau pihak lain. Di sini termasuk pertukaran informasi dengan
                  perusahaan dan organisasi lain untuk tujuan perlindungan
                  terhadap penipuan dan pengurangan resiko kredit.
                </li>
              </ul>
            </div>
            <div>
              <p className="font-bold">Penayangan Konten</p>
              <ul>
                <li>
                  Anda diizinkan mengupload konten menggunakan salah satu fitur
                  Bisakasir.id dengan syarat Anda wajib menghindari tulisan
                  maupun gambar yang melanggar Hukum Syariat Islam, Hukum Negara
                  Republik Indonesia dan Hukum SARA (Suku, Agama, Ras, dan
                  Antargolongan).
                </li>
                <li>
                  Pihak Bisakasir.id berhak menurunkan dan tidak menayangkan
                  konten yang tidak sesuai dengan peraturan.
                </li>
              </ul>
            </div>
            <div>
            <p className="font-bold">Penggunaan informasi</p>
            <p>
              Kami menggunakan informasi yang dimiliki mengenai Anda dengan
              cara-cara sebagai berikut:
            </p>
            <ul>
              <li>
                Untuk memastikan bahwa isi dari situs Kami disajikan dengan cara
                yang paling efektif untuk Anda dan untuk komputer Anda.
              </li>
              <li>
                Untuk memberikan kepada Anda, informasi, produk atau layanan
                yang diminta dari Kami atau yang Kami rasa mungkin penting bagi
                Anda, dimana Anda setuju untuk dihubungi untuk tujuan tersebut.
              </li>
              <li>
                Untuk melaksanakan kewajiban Kami yang timbul dari suatu kontrak
                yang diadakan antara Anda dan Kami.
              </li>
              <li>
                Untuk memungkinkan Anda ambil bagian dalam fitur interaktif
                layanan Kami, sewaktu Anda memilih demikian
              </li>
              <li>
                Untuk memberitahu Anda mengenai perubahan terhadap layanan Kami.
              </li>
            </ul>
          </div>
          <div>
            <p className="font-bold">Hak Anda</p>
            <ul>
              <li>
                Anda berhak meminta Kami untuk tidak memproses data pribadi Anda
                untuk tujuan pemasaran. Kami biasanya akan memberitahu Anda
                (sebelum mengumpulkan data Anda) apabila Kami bermaksud
                menggunakan data Anda untuk tujuan tersebut atau apabila Kami
                bermaksud mengungkapkan informasi Anda kepada pihak ketiga untuk
                tujuan tersebut. Anda dapat menggunakan hak Anda untuk mencegah
                pemrosesan tersebut secara terperinci dengan menghubungi Kami
                melalui e-mail support@Bisakasir.id
              </li>
              <li>
                Situs Kami, dari waktu ke waktu, dapat berisi link ke dan/atau
                dari situs web jaringan mitra, pengiklan dan afiliasi Kami.
                Apabila Anda mengikuti link ke salah satu dari situs-situs web
                ini, perlu diperhatikan bahwa situs-situs web ini memiliki
                kebijakan hak-pribadinya sendiri dan Kami tidak memiliki
                tanggung jawab atau menanggung kewajiban atas kebijakan
                tersebut. Silakan periksa kebijakan ini sebelum Anda menyerahkan
                data pribadi ke situs-situs web ini.
              </li>
            </ul>
          </div>
          <div>
            <p className="font-bold">
              Perubahan atas kebijakan hak-pribadi Kami
            </p>
            <ul>
              <li>
                Perubahan yang dapat Kami lakukan atas kebijakan hak-pribadi
                Kami di masa mendatang akan ditempatkan di halaman ini dan
                bilamana dipandang tepat, akan diberitahukan kepada Anda melalui
                e-mail.
              </li>
            </ul>
          </div>
          <div>
            <p className="font-bold">Penghasilan dan Penafian Hukum</p>
            <ul>
              <li>
                Kami percaya bahwasannya setiap manusia sudah ditentukan
                rezekinya oleh Allah Subhanahu Wata’ala sesuai dengan
                kehendak-Nya. Akan tetapi sebagaimana dinyatakan dalam hukum,
                Kami tidak dapat dan tidak menjamin apapun tentang kemampuan
                Anda sendiri dalam menghasilkan atau mendapatkan uang dari ide,
                informasi, program, atau strategi yang Kami berikan.
              </li>
              <li>
                Bagaimanapun, tidak ada janji atau jaminan masa depan di halaman
                ini atau situs web atau email Kami. Setiap angka keuangan yang
                direferensikan di sini, atau di situs atau email Kami, hanyalah
                perkiraan atau proyeksi atau hasil di masa lalu, dan tidak boleh
                dianggap tepat, aktual atau sebagai janji potensi penghasilan -
                semua angka hanya ilustrasi saja.
              </li>
              <li>
                Kami tidak mengenal Anda secara langsung dan apapun yang akan
                terjadi itu karena Anda sepakat untuk bekerjasama dengan Kami.
                Namun, InsyaAllah Kami siap membantu Anda memberikan strategi
                terhebat sehingga bisnis online Anda bisa maju lebih cepat.
                Biidzinillah
              </li>
            </ul>
          </div>
          <div>
            <p className="font-bold">Pihak Yang Dapat Dihubungi</p>
            <p>
              Jika Anda memiliki pertanyaan, bantuan maupun saran silahkan kirim
              e-mail ke support@Bisakasir.id. Kami ucapkan Terima kasih,
              Jazakumullahu Khoiron Katsiro sudah berkunjung dan mendukung
              produk lokal karya anak negeri
            </p>
            <h3 className="font-bold">Copyright © 2021 by PT. Amanah Karya Indonesia.</h3>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
}

export default Terms;
