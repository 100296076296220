import React from 'react';
import { useHistory } from 'react-router-dom';

function ForgetPassword() {
  const history = useHistory();

  return (
    <div>
      <div className="flex flex-col items-center px-6 py-12 h-screen md:hidden">
        <div className="hidden md:block">
          <img src="/images/landing-pages/logo.png" alt="logo" />
        </div>

        <img
          src="/images/verification_password/checklist-icon-primary.png"
          alt="checklist"
          className="mt-24"
        />
        <div className="mt-16 mb-8">
          <h1 className="text-2xl font-bold">Password Berhasil diubah!</h1>
        </div>
        <button
          type="button"
          onClick={() => history.push('/')}
          className="w-3/4 bg-primary rounded-full py-3 text-white font-semibold text-xl mt-8"
        >
          {' '}
          Halaman Utama
        </button>
      </div>
      <div
        className="h-screen bg-no-repeat bg-cover hidden md:block"
        style={{
          backgroundImage:
            "url('images/verification_password/bg-opacity-blue.png')",
        }}
      >
        <div className="flex justify-center items-center h-full ">
          <div className=" bg-white h-1/2 py-16 px-20 rounded-3xl w-2/5 flex flex-col items-center">
            <img
              src="images/verification_password/checklist-icon-primary.png"
              alt="checklist"
              className="w-16 h-16 mb-4"
            />
            <p className="text-3xl font-bold mt-6">Password berhasil diubah!</p>
            <button
              onClick={() => history.push('/')}
              className="w-3/4 bg-primary py-3 rounded-full text-lg text-white font-semibold mt-12"
            >
              Halaman Utama
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;
