import React from 'react';
import { Link } from 'react-router-dom';

function Login() {
  return (
    <main>
      <section
        className=" 
          py-32
          md:py-0
          md:h-screen
          w-10/12
          mx-auto
          grid grid-cols-1
          md:grid-cols-2
          justify-items-center
          items-center
        "
      >
        <div className="hidden md:inline">
          <img
            src="images/login-pages/Illustration-login.png"
            alt="Login Illustration"
          />
        </div>
        <div className="w-11/12 md:w-2/3">
          <div className="mb-10">
            <h1 className="text-4xl font-bold">Login</h1>
            <p>
              Belum punya akun?
              <Link className="text-primary underline" to="">
                Daftar gratis.
              </Link>
            </p>
          </div>
          <div className="flex flex-col w-full my-2">
            <label htmlFor="email" className="my-2">
              E-mail / Nomor Handphone
            </label>
            <input
              type="text"
              placeholder="example@gmail.com"
              name=""
              id=""
              className="w-full py-2 px-4 border-2 rounded-md"
            />
          </div>
          <div className="flex flex-col w-full my-2">
            <label htmlFor="password" className="my-2">
              Password
            </label>
            <input
              type="text"
              placeholder="Ketik Password Anda"
              name=""
              id=""
              className="w-full py-2 px-4 border-2 rounded-md"
            />
          </div>
          <div className="flex flex-row justify-between my-4">
            <div>
              <input type="checkbox" name="" id="" />
              <label>ingat saya</label>
            </div>
            <Link to="">Lupa Password</Link>
          </div>
          <div className="w-full flex justify-center">
            <button
              className="
                border-2 border-primary
                rounded-full
                py-3
                md:py-2
                w-2/3
                md:w-full
                bg-primary
                text-white
                font-bold
              "
            >
              Log In
            </button>
          </div>
          <p className="my-6 text-center text-gray-400">atau login dengan akun</p>
          <div className="w-full flex justify-center items-center text-xs md:text-base">
            <button
              className="
                flex
                border-2
                rounded-full
                items-center
                px-3
                py-2
                md:px-5 md:py-2
              "
            >
              <img
                src="images/login-pages/google.png"
                className="md:mr-2"
                alt=""
              />
              <p>Google</p>
            </button>
            <button
              className="
                flex
                border-2
                rounded-full
                items-center
                px-3
                py-2
                md:px-5 md:py-2
                mx-4
              "
            >
              <img
                src="images/login-pages/facebook.png"
                className="md:mr-2"
                alt=""
              />
              <p>Facebook</p>
            </button>
            <button
              className="
                flex
                border-2
                rounded-full
                items-center
                px-3
                py-2
                md:px-5 md:py-2
              "
            >
              <img
                src="images/login-pages/instagram.png"
                className="md:mr-2"
                alt=""
              />
              <p>Instagram</p>
            </button>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Login;
